<template>
  <div id="app">
    <main class="content">
      <h1 class="logo">
        <img src="./assets/images/logo.svg" width="460" height="113" alt />
        <span>
          Prof.ᵃ Dra. Elza Daniel de Mello – Gastroenterologia Pediátrica e
          Nutrologia
        </span>
      </h1>

      <div class="information">
        <div class="text">
          <span class="txt office-sep sep">
            CRM-PB 7589 / CRN₆ 42164
          </span>
          <br />

          <dl>
            <dt class="txt phone-sep sep">Telemedicina (Whatsapp)</dt>
            <dd>
              <a
                class="txt signature_phone-target sig-hide"
                href="https://api.whatsapp.com/send?phone=5551991231965&text=&source=&data="
                >+55 (51) 99123-1965</a
              >
            </dd>
            <dt class="txt phone-sep sep">Crianças (Telefone)</dt>
            <dd>
              <a
                class="txt signature_phone-target sig-hide"
                href="tel:+558332224990"
                >+55 (83) 3222-4990</a
              >
            </dd>
            <dt class="txt phone-sep sep">Adolescentes e Adultos (Telefone)</dt>
            <dd>
              <a
                class="txt signature_phone-target sig-hide"
                href="tel:+5583996515896"
                >+55 (83) 99651-5896</a
              >
            </dd>
          </dl>
          <p>
            <span class="txt signature_address-target sig-hide"
              >Av. Min. José Américo de Almeida, 1280</span
            >
            <br />
            <span class="txt signature_address2-target sig-hide"
              >58046-008, João Pessoa - PB</span
            >

            <!--eslint-disable vue/no-v-html-->
            <span
              class="email-sep break"
              style="display: block;"
              v-html="email"
            >
              <noscript
                >Please enable Javascript to see the email address</noscript
              >
            </span>
            <!--eslint-enable vue/no-v-html-->
          </p>
        </div>
        <a
          href="https://buscatextual.cnpq.br/buscatextual/visualizacv.do?metodo=apresentar&id=K4758691Y7"
          class="vita"
        >
          <span></span>Curriculum vitae
        </a>
      </div>

      <h2 class="como-chegar">Como Chegar</h2>
      <div class="google-maps-wrapper">
        <iframe
          title="Google Maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1726.9115170017226!2d-51.18484836457533!3d-30.04193445298559!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9519782171fec24d%3A0x20af2868167fc422!2sProfa+Dra+Elza+Daniel+de+Mello!5e0!3m2!1sde!2sus!4v1468011986830"
          class="googlemaps"
          frameborder="0"
          style="border:0"
          allowfullscreen
          loading="lazy"
        />
      </div>
    </main>

    <footer class="footer">
      <p>Design & Developement</p>
      <a href="https://pschen.de">Dominik Pschenitschni</a> © 2023
    </footer>
  </div>
</template>

<script>
import "normalize.css";

const getEmail = () => {
  // prettier-ignore
  const pbdhvpt = ['l', 'm', 'a', 't', 'o', 'o', 'a', 'o', ' ', 'o', '@', 't', 'a', '>', 'm', 'a', '<', 't',
        'i', '<', 'm', '.', 'c', 'm', 'm', 'e', '=', 'r', 'i', 'c', 'e', 'z', 'l', 'l', 'n', 'l', 'a', ':',
        'a', 'o', '.', 'o', 'o', '"', 'e', 'z', '"', 'h', '>', 'f', 'n', '"', '@', 'e', 's', 'l', 'l', 'c',
        'a', 't', 'l', 'l', 'c', 't', 'o', 'e', 'o', '/', 'a', ' ', '"', 'c', 'e', 'a', 's', 'l', 'm', '='
      ];

  // prettier-ignore
  const vojlvrn = [62, 36, 41, 19, 17, 69, 64, 32, 2, 72, 60, 13, 27, 77, 9, 1, 0, 21, 49, 74, 47, 33, 16,
        28, 73, 29, 7, 4, 11, 34, 24, 63, 30, 50, 55, 40, 10, 15, 76, 54, 70, 35, 22, 37, 61, 26, 8, 3, 52,
        6, 18, 51, 23, 46, 42, 68, 67, 53, 48, 56, 25, 31, 39, 58, 14, 66, 59, 75, 57, 38, 45, 71, 5, 20,
        43, 12, 65, 44
      ];

  const kuhnobm = [];

  for (var i = 0; i < vojlvrn.length; i++) {
    kuhnobm[vojlvrn[i]] = pbdhvpt[i];
  }

  return kuhnobm.join("");
};

export default {
  name: "App",

  computed: {
    email: getEmail
  }
};
</script>

<style lang="scss">
@font-face {
  font-family: "Bitter";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("./assets/fonts/Bitter-regular.eot");
  src: url("./assets/fonts/Bitter-regular.eot?#iefix")
      format("embedded-opentype"),
    local("Bitter-Regular"), local("Bitter-regular"),
    url("./assets/fonts/Bitter-regular.woff2") format("woff2"),
    url("./assets/fonts/Bitter-regular.woff") format("woff"),
    url("./assets/fonts/Bitter-regular.ttf") format("truetype"),
    url("./assets/fonts/Bitter-regular.svg#Bitter") format("svg");
}

@font-face {
  font-family: "Oswald";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("./assets/fonts/Oswald-regular.eot");
  src: url("./assets/fonts/Oswald-regular.eot?#iefix")
      format("embedded-opentype"),
    local("Oswald Regular"), local("Oswald-regular"),
    url("./assets/fonts/Oswald-regular.woff2") format("woff2"),
    url("./assets/fonts/Oswald-regular.woff") format("woff"),
    url("./assets/fonts/Oswald-regular.ttf") format("truetype"),
    url("./assets/fonts/Oswald-regular.svg#Oswald") format("svg");
}

$f-alternative: Oswald, Georgia, Times New Roman, Courier, serif;

// colors
$c-highlight: #73ad5f;
$c-link: #45842f;

$c-black: #000;
$c-dark: #212121;
$c-gray: #f9f9f9;
$c-light-grey: lighten($c-dark, 75);
$c-white: #fff;

//timeings
$time: 0.3s;
$time-short: 0.25s;

// breakpoints
$d-mobile-s: 320px; // Custom, iPhone Retina
$d-mobile-m: 375px; // Extra Small Devices, Phones
$d-mobile-l: 425px; // Small Devices, Phones
$d-tablet: 768px; // Small Devices, Tablets
$d-laptop: 1024px; // Medium Devices, Desktops
$d-laptop-l: 1440px; // Large Devices, Wide Screens

// mixins
//***************************************/
@mixin smaller($px) {
  @media only screen and (max-width: em($px)) {
    @content;
  }
}

@mixin greater($px) {
  @media only screen and (min-width: em($px)) {
    @content;
  }
}

@mixin visability-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

// mixin to convert px to ems
$browser-context: 16;

@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1em;
}

::selection {
  background: lighten($c-highlight, 20);
  text-shadow: none;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  vertical-align: middle;
}

p {
  margin: 0;
}

dl {
  margin-top: 0;
  margin-bottom: 0;
}

dd {
  margin-left: 0;
}

:root {
  font: 14px / 1.6 "Bitter", Georgia, Times New Roman, Courier, serif;
  background-color: $c-highlight;
  background-image: url("./assets/images/bg.jpg");
  background-image: url("./assets/images/bg.webp");
  background-attachment: fixed;
  padding: 10px 10px 0;

  @include greater($d-mobile-m) {
    padding: 20px 20px 0;
  }
  @include greater($d-mobile-l) {
    font-size: 16px;
  }
}

.content {
  margin: 0 auto;
  max-width: 688px;
  min-width: 200px;
  background: $c-white;
  box-shadow: 0 6px 100px rgba($c-black, 0.35);
  border-radius: 4px;

  // fix margin-bottom of last element
  @include clearfix;

  // set above shadow
  z-index: 0;
  position: relative;

  @include greater($d-tablet) {
    margin: 40px auto;
  }

  > * {
    margin-left: 20px;
    margin-right: 20px;

    @include greater($d-tablet) {
      margin-left: 40px;
      margin-right: 40px;
    }
  }
}

.logo {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 40px;
  padding-bottom: 40px;

  img {
    max-width: 460px;
    width: 100%;
    height: auto;
  }

  span {
    @include visability-hidden();
  }
}

.information {
  position: relative;

  @include greater(570px) {
    display: flex;
  }

  > div {
    flex-grow: 1;
  }
}

.text {
  p {
    margin-bottom: 20px;
  }

  a {
    padding-bottom: 1px;
    box-shadow: 0 1px 0 lighten($c-highlight, 20);
    color: $c-link;
    transition: all $time-short ease-out;

    &:hover {
      box-shadow: 0 2px 0 $c-highlight;
    }
  }

  dl {
    display: grid;
    grid-template-columns: auto 1fr;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  dt {
    margin-right: 10px;
  }

  dt ~ dt,
  dd ~ dd {
    // ensure minimal tap target distance
    margin-top: 8px;
  }
}

.vita {
  display: block;
  padding: 25px 0;
  font-family: $f-alternative;
  font-size: 16px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border-radius: 5px;
  transition: all $time ease-out;

  @include smaller(570px) {
    margin-top: 40px;
    background-color: $c-white;
    box-shadow: inset 0 0 0 2px $c-light-grey;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 20px;
      right: 20px;
      bottom: 0;
      box-shadow: 0 0 50px rgba($c-black, 0.3);
      z-index: -1;
      border-radius: inherit;
      opacity: 0;
      transition: opacity $time ease-out;
    }

    &:hover {
      box-shadow: inset 0 0 0 2px $c-highlight;

      &::after {
        opacity: 1;
      }
    }
  }

  @include greater(570px) {
    // prettier-ignore
    box-shadow:
      0 2px 40px rgba($c-dark, 0.2),
      inset 0 0 0 2px $c-light-grey;
    transform: rotate(-2deg);
    width: 148px;
    transform-origin: bottom center;

    &:hover {
      // prettier-ignore
      box-shadow:
        0 2px 40px rgba($c-dark, 0.2),
        inset 0 0 0 2px $c-highlight;
    }

    &::before,
    &::after {
      border-top: 4px solid lighten($c-dark, 60);
      content: "";
      display: block;
      left: 10%;
      position: absolute;
      top: 70px;
      width: 80%;
    }

    &::after {
      top: 90px;
    }

    span {
      border-top: 4px solid lighten($c-dark, 60);
      display: block;
      left: 10%;
      position: absolute;
      top: 110px;
      width: 80%;

      &::before,
      &::after {
        border-top: 4px solid lighten($c-dark, 60);
        bottom: -20px;
        content: "";
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
      }

      &::after {
        bottom: 40px;
      }
    }
  }
}

.signature_phone-target,
.signature_fax-target {
  color: $c-dark;
}

.como-chegar {
  position: relative;
  margin: 40px 20px;
  font-family: $f-alternative;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: $c-link;
  font-weight: normal;
  display: flex;
  align-items: center;

  @include greater($d-tablet) {
    margin: 40px;
  }

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-top: 2px solid $c-light-grey;
    top: 50%;
  }

  &::before {
    margin-right: 10px;
  }

  &::after {
    margin-left: 10px;
  }
}

.google-maps-wrapper {
  padding-bottom: 100%;
  margin: 40px 0;
  position: relative;

  @include greater($d-mobile-m) {
    padding-bottom: 75%;
  }

  @include greater($d-mobile-l) {
    margin: 40px 20px;
    padding-bottom: 56.25%;
    box-shadow: 0 1px 3px rgba($c-black, 0.3);
  }

  @include greater($d-tablet) {
    margin: 40px;
  }

  iframe {
    position: absolute !important;
    width: 100%;
    height: 100%;
  }
}

.footer {
  margin: 40px;
  font-size: 12px;
  opacity: 0.7;
  text-align: center;

  p {
    @include greater($d-mobile-l) {
      display: inline;
      margin-right: 0.35ch;
    }
  }

  a {
    padding-bottom: 1px;
    box-shadow: 0 1px 0 $c-dark;
    transition: all $time-short ease-out;

    &:hover {
      box-shadow: 0 2px 0 $c-dark;
    }
  }
}
</style>
